import { useState } from "react";
import "./Blog.scss";

function Blog() {
  const [showBlog1, setShowBlog1] = useState(false);
  const [showBlog2, setShowBlog2] = useState(false);
  const [showBlog3, setShowBlog3] = useState(false);
  const [showBlog4, setShowBlog4] = useState(false);
  // const [showBlog5, setShowBlog5] = useState(false);

  const handleToggle1 = () => {
    setShowBlog1(!showBlog1);
  };

  const handleToggle2 = () => {
    setShowBlog2(!showBlog2);
  };

  const handleToggle3 = () => {
    setShowBlog3(!showBlog3);
  };

  const handleToggle4 = () => {
    setShowBlog4(!showBlog4);
  };

  // const handleToggle5 = () => {
  //   setShowBlog5(!showBlog5);
  // };

  return (
    <div className="blog">
      <h1 className="blog__blog">Goost Blog</h1>

      {/* <div className="blog__card">
        <div className="blog__image blog__image4"></div>
        <div className="blog__content">
          <h2 className="blog__heading">Going Freelance</h2>
          <h4>March 2024</h4>

          <div className="blog__blurb">
            Hey there, fellow developers and aspiring developers!
            Transitioning into freelance web development has been an exhilarating journey filled
            with challenges and learning opportunities. Recently, I had the
            privilege of working with a client who owns a vending machine
            business. In this blog post, I'll take you through the process of
            building their website in React and the exciting new projects
            that followed.
          </div>
          {showBlog5 && (
            <div className="blog__hidden">
              <h4>Project Overview</h4>
              When I first connected with my client, they expressed a need for a
              modern and user-friendly website to showcase their vending machine
              services. After discussing their requirements and vision, we
              decided to build a single-page application (SPA) using React.
              This decision allowed us to create a dynamic and responsive
              website that would enhance the user experience.
              
              <h4>Why React.js</h4> Choosing the right technology for the
              project was crucial, and after careful consideration, React.js
              emerged as the perfect fit and I love coding in React.js. Its
              component-based architecture, virtual DOM, and efficient updates
              made it an ideal choice for creating a dynamic and responsive user
              interface.
              <h3>Key Features</h3>
              <h4>Curated Product Listings</h4>
              As with any project, there were challenges along the way. From
              fine-tuning the design to implementing complex functionalities,
              each step presented its own set of obstacles. However, with
              perseverance and collaboration, we were able to overcome these
              challenges and deliver a finished product that exceeded
              expectations. Seeing the client's satisfaction with the final
              result was incredibly rewarding and reaffirmed my passion for web
              development.
              <h4>User-Friendly Navigation</h4>
              React Router was employed to create a seamless browsing
              experience. Users can easily navigate between categories, explore
              featured products, and discover new arrivals.
              <h4>Responsive Design</h4>
              Following the successful launch of the website, my client
              expressed interest in expanding their online presence further.
              They requested additional sections to be added to the site, as
              well as the creation of a Google Business page and implementation
              of email marketing strategies. This presented an exciting
              opportunity to continue our partnership and further enhance their
              digital presence.
              <h4>Client Satisfaction and Ongoing Development</h4>
              The client expressed immense satisfaction with the delivered
              project, highlighting the site's aesthetics and user-friendly
              features. Following the successful launch, I am currently working
              on enhancing the site with new features to elevate the affiliate
              marketing experience.
              <h4>Upcoming Features</h4>
              <em>Advanced Search Functionality</em> Implementing an advanced
              search feature to allow users to quickly find specific products
              based on keywords, categories, and preferences.
              <em>User Accounts and Wishlists</em>
              As I reflect on this journey, I'm filled with gratitude for the
              opportunity to work with such a fantastic client and bring their
              vision to life. Moving forward, I'm eager to take on new
              challenges, continue honing my skills, and pursue further
              freelance opportunities. Building relationships with clients and
              helping them achieve their goals is what drives me, and I can't
              wait to see what the future holds.
              <h4>Conclusion</h4>
              My experience working with a vending machine business on their
              website project has been both fulfilling and inspiring. It's
              reaffirmed my passion for web development and solidified my
              decision to pursue freelancing. I'm grateful for the trust my
              client placed in me and the opportunity to contribute to their
              success. As I embark on new projects and collaborations, I'm
              excited to see where this journey will take me. Thank you for
              joining me on this adventure, and I look forward to sharing more
              of my freelancing experiences with you in the future.
            </div>
          )}
          <button className="blog__read" onClick={handleToggle5}>
            {showBlog5 ? "Read Less" : "Read More"}
          </button>
        </div>
      </div> */}

      <div className="blog__card">
        <div className="blog__image blog__image4"></div>
        <div className="blog__content">
          <h2 className="blog__heading">
            Building a Curated Amazon Affiliate Site
          </h2>
          <h4>January 2023</h4>

          <div className="blog__blurb">
            Hey there, fellow developers and aspiring developers! "Happy New
            Year" I'm back with another installment on my journey as a freelance
            web developer and blogger. I'm excited to share the story of my next
            project, building a Curated Amazon Affiliate site showcasing
            handpicked items from Amazon from quality and reliable brands.
          </div>
          {showBlog4 && (
            <div className="blog__hidden">
              <h4>Project Overview</h4>
              As a full-stack web developer, I recently had the exciting
              opportunity to work on a unique project, a curated Amazon
              affiliate marketing site. The goal was to create a platform
              showcasing handpicked{" "}
              <a href="https://www.amazon.ca" target="blank" rel="noreferrer">
                Amazon
              </a>{" "}
              finds from reliable companies known for their quality products. In
              this blog post, I'll take you through the journey of building this
              site using React.js and share insights into the development
              process. The project was commissioned by a client who envisioned
              an affiliate marketing site that stood out in terms of both design
              and functionality. The site would feature curated products from
              Amazon, offering visitors a carefully selected collection of items
              from trusted companies.
              <h4>Why React.js</h4> Choosing the right technology for the
              project was crucial, and after careful consideration, React.js
              emerged as the perfect fit and I love coding in React.js. Its
              component-based architecture, virtual DOM, and efficient updates
              made it an ideal choice for creating a dynamic and responsive user
              interface.
              <h3>Key Features</h3>
              <h4>Curated Product Listings</h4>
              Leveraging React components, I implemented a clean and intuitive
              interface for displaying the curated products. Each product card
              provides product information, including images, descriptions, and
              direct Amazon links.
              <h4>User-Friendly Navigation</h4>
              React Router was employed to create a seamless browsing
              experience. Users can easily navigate between categories, explore
              featured products, and discover new arrivals.
              <h4>Responsive Design</h4>
              Ensuring a consistent user experience across devices was a
              priority. With the help of responsive design principles and media
              queries, the site adapts gracefully to different screen sizes.
              <h4>Client Satisfaction and Ongoing Development</h4>
              The client expressed immense satisfaction with the delivered
              project, highlighting the site's aesthetics and user-friendly
              features. Following the successful launch, I am currently working
              on enhancing the site with new features to elevate the affiliate
              marketing experience.
              <h4>Upcoming Features</h4>
              <em>Advanced Search Functionality</em> Implementing an advanced
              search feature to allow users to quickly find specific products
              based on keywords, categories, and preferences.
              <em>User Accounts and Wishlists</em>
              Introducing user accounts with personalized wishlists, enabling
              visitors to save and revisit their favorite curated finds.
              <em>Real-time Updates</em> Incorporating real-time updates to
              ensure that the curated product listings are always current and
              reflective of the latest Amazon offerings.
              <h4>Conclusion</h4>
              Building this curated Amazon affiliate marketing site with
              React.js has been an enriching experience. The combination of
              React's flexibility and the client's vision for a unique platform
              has resulted in a user-friendly and visually appealing website. As
              development continues, I am excited about the potential for
              additional features that will further enhance the overall user
              experience. Stay tuned for updates as the project evolves, and
              feel free to explore the curated collection on
              <a
                href="http://www.curateclub.co"
                target="blank"
                rel="noreferrer"
              >
                Visit Curate Club
              </a>
            </div>
          )}
          <button className="blog__read" onClick={handleToggle4}>
            {showBlog4 ? "Read Less" : "Read More"}
          </button>
        </div>
      </div>

      <div className="blog__card">
        <div className="blog__image blog__image3"></div>
        <div className="blog__content">
          <h2 className="blog__heading">Freelance Web Development</h2>
          <h4>October 2023</h4>
          <div className="blog__blurb">
            Hey there, fellow developers and aspiring developers! I'm back with
            another installment on my journey as a freelance web developer and
            blogger. I'm excited to share the story of my first paid programming
            gig since completing my coding bootcamp. It was an experience that
            tested my skills, patience, and determination. My boss, the owner of
            a construction company, wanted a website for his business, and this
            marked the beginning of a challenging yet rewarding journey.
          </div>
          {showBlog3 && (
            <div className="blog__hidden">
              <h4>Setting the Stage</h4>I took on a website build with five
              pages for my boss's construction company. It seemed like a
              manageable task but took a lot longer than I expected or an
              experienced programmer could do it in. When we began, my boss and
              I agreed on a rate of $500, and not much direction, so I eagerly
              dived into the project.
              <h4>Challenges as a New Developer</h4>I enjoy coding in React,
              it's what I know so thats what I use. It's powerful, no doubt, but
              the learning curve is steep. With minimal project experience, what
              takes an experienced programmer just a few hours, takes me a lot
              longer.
              <h4>Challenges</h4>
              One of the earliest challenges I faced was the absence of clear
              direction from my boss. As a developer, I thrive on structure and
              well-defined goals, but in this case, I had to adapt quickly to a
              more open-ended approach. This meant spending extra time
              brainstorming and planning to ensure the website would meet his
              expectations. The second major hurdle was the timeline. I soon
              realized that my boss's schedule didn't align with mine, and
              getting the necessary content for the website was a prolonged
              process. Two weeks turned into a month, and I found myself waiting
              for images, text, and other materials to move forward.
              <h4>Photo Editing</h4>
              One aspect of the project that extended the timeline was the need
              for photo editing. The pictures my boss provided were taken with
              an iPhone, and while they captured the essence of his work, they
              required significant editing in{" "}
              <a
                href="https://www.adobe.com/ca/products/photoshop.html"
                target="_blank"
                rel="noreferrer"
              >
                Adobe Photoshop
              </a>{" "}
              to fit seamlessly into the website's design. Cropping,
              brightening, and enhancing were among the tasks I tackled to
              ensure a polished and professional appearance.
              <h4>Setting the rate</h4>
              When it comes to setting rates, you have a few options. Some
              freelancers prefer hourly rates, while others opt for fixed
              project rates, each has its pros and cons. For this project, we
              went with a fixed rate as I am a new programmer and knew it would
              take longer than it should.
              <h4>Perfectionism at Play</h4>
              I've always been a bit of a perfectionist and I tend to notice
              even the tiniest details that could be improved. This project was
              no exception. Every time I looked at the website, I saw
              opportunities for enhancement. It's a characteristic that drives
              me to deliver high-quality work, but it can also make it
              challenging to wrap up a project. I had to strike a balance
              between striving for perfection and ensuring timely delivery.
              <h4>The Future and Educational Value</h4>
              As the project drew to a close, I realized that, given my boss's
              limited knowledge of programming, I would be responsible for all
              future updates unless he chooses to hire another developer. This
              presents an opportunity to continue building my skills and provide
              ongoing support. This experience wasn't just about earning $500;
              it was a valuable educational journey and a significant addition
              to my portfolio. I honed my problem-solving skills, learned to
              adapt to new challenges, and deepened my expertise in React. It
              reinforced the importance of clear communication, planning, and
              setting realistic expectations with clients.
              <br />
              As I reflect on my first paid programming job, I'm grateful for
              the knowledge gained, and I look forward to future projects that
              will further shape my journey as a web developer. Remember that
              every project is a stepping stone towards growth, and each
              challenge offers an opportunity for improvement. Thank you for
              joining me on this part of my journey, and I'm here to support you
              on yours. If you have questions or want to share your experiences,
              feel free to drop me an email. Until the next update, keep coding
              and keep learning!
            </div>
          )}
          <button className="blog__read" onClick={handleToggle3}>
            {showBlog3 ? "Read Less" : "Read More"}
          </button>
        </div>
      </div>

      <div className="blog__card">
        <div className="blog__image blog__image2"></div>
        <div className="blog__content">
          <h2 className="blog__heading">Post Bootcamp</h2>
          <h4>September 2023</h4>
          <div className="blog__blurb">
            Hey there, fellow web developers and aspiring web developers! If
            you've ever wondered what life looks like after a coding bootcamp,
            let me share my personal journey with you.
            {showBlog2 && (
              <div className="blog__hidden">
                I hope my experience can provide some guidance and inspiration
                as you carve your path into the world of web development. <br />
                <h4>Taking a Breather and Sharpening My Skills</h4>
                After completing my coding bootcamp, I decided to take a
                breather and dive into a world of code projects and portfolio
                building. Two months off allowed me to hone my coding skills,
                work on exciting personal projects, and create a compelling
                portfolio that showcased my abilities and passion for web
                development. But let's get real – chasing your dreams doesn't
                always pay the bills. So, I took a step back and returned to my
                old gig as a carpenter. It might sound like a world away from
                web development, but it was a practical choice to boost my bank
                balance. And it turned out to be a rewarding experience for
                reasons I never expected.
                <h4>Building a Bridge to Web Development</h4> While I was
                hammering nails and sawing wood, an opportunity presented
                itself. My old boss needed a website for his construction
                company. It was a lightbulb moment. I could marry my carpentry
                skills with my newfound web development prowess. This project
                allowed me to work directly with the client and gain a deep
                understanding of their business. Suddenly, I wasn't just a web
                developer; I was a problem solver, a digital architect who could
                bridge the gap between the construction world and the online
                one. Building that construction website was more than just
                coding; it was about creating a digital space that reflected the
                essence of the business. This experience opened my eyes to the
                vast potential of web development, and I realized that web
                development is not just about writing code; it's about
                understanding businesses, their needs, and how technology can
                help them thrive.
                <h4>Balancing Act: Part-Time Carpentry and Web Development</h4>
                As time passed, I found myself juggling two worlds. Part-time
                carpentry paid the bills, but web development was my passion.
                Working on the construction website, along with personal
                projects, gave me invaluable hands-on experience that elevated
                my skills. In addition to my part-time carpentry job and website
                development work, I found an opportunity to give back. I joined
                a non-profit organization, using my tech skills to help
                streamline their operations. This wasn't just about coding; it
                was about making a meaningful impact and witnessing firsthand
                how technology can empower positive change.
                <h4>Looking Ahead: My Journey to Full-Time Web Development</h4>
                My journey is far from over, but it's clear to me now that the
                web development world is where I want to be. The diversity of my
                experiences, from coding to carpentry, from corporate projects
                to non-profit work, has equipped me with a unique perspective
                and a versatile skill set. I'm ready to take the next step and
                secure a full-time role in web development. So, if you're in the
                same boat, wondering how to transition from part-time work and
                side projects to full-time web development, remember that your
                journey is unique and filled with valuable experiences. Embrace
                every opportunity, and don't be afraid to combine your skills
                and passion. The path to your dream job may be winding, but
                every twist and turn will make you a better web developer and a
                more well-rounded professional. Cheers to your future in web
                development! Keep coding, keep building, and most importantly,
                keep pursuing your dreams. The web development world is waiting
                for you to leave your mark.
              </div>
            )}
          </div>
          <button className="blog__read" onClick={handleToggle2}>
            {showBlog2 ? "Read Less" : "Read More"}
          </button>
        </div>
      </div>

      <div className="blog__card">
        <div className="blog__image blog__image1"></div>
        <div className="blog__content">
          <h2 className="blog__heading">Navigating the Coding Journey</h2>
          <h4>August 2023</h4>
          <div className="blog__blurb">
            The pandemic brought about numerous challenges, but it also provided
            many with the opportunity to explore new interests and hobbies. For
            me, it was the world of coding, and my journey began with online
            courses from{" "}
            <a href="https://www.udemy.com" target="_blank" rel="noreferrer">
              Udemy.com
            </a>{" "}
            and{" "}
            <a href="https://edx.org" target="_blank" rel="noreferrer">
              edx.org
            </a>
            .
            {showBlog1 && (
              <div className="blog__hidden">
                However, I quickly realized that my learning approach lacked
                structure, leading me to dabble in various languages without
                mastering any.
                <br />
                Seeking a more focused path, I enrolled in a fast-paced bootcamp
                with{" "}
                <a
                  href="https://brainstation.io"
                  target="_blank"
                  rel="noreferrer"
                >
                  BrainStation
                </a>
                . While the experience was intense and enriched my coding
                skills, it also left me feeling overwhelmed and burnt out. Now,
                after a month of self-directed learning, I'm building projects
                and shaping my portfolio, eager to step into the job market that
                seems challenging yet full of possibilities.
                <br />
                <h4>The Unstructured Beginnings</h4> My coding journey started
                with online courses and tutorials during the pandemic. The
                flexibility of online learning allowed me to dive into various
                languages, from HTML and CSS to JavaScript, Swift, Python, and
                Solidity. However, the lack of structure hindered my progress,
                leading to frustration and leaving me stranded whenever I faced
                challenges. It became apparent that I needed a more organized
                approach to truly grasp the concepts.
                <br />
                <h4>Bootcamp Experience</h4>
                In search of a more structured learning environment, I decided
                to take the plunge and enroll in a Full Stack Developer bootcamp
                with
                <a
                  href="https://www.brainstation.io"
                  target="_blank"
                  rel="noreferrer"
                >
                  {" "}
                  BrainStation
                </a>
                . The course promised to be fast-paced, and it was exactly that.
                While the intensity helped me learn a lot in a short amount of
                time, it also had its downsides. The sheer amount of information
                thrown at me made it challenging to retain everything
                effectively. The pressure to keep up with the pace and perform
                at a high level took a toll on my well-being.
                <br />
                <h4>Coping with Burnout</h4>
                After completing the bootcamp, I realized that the breakneck
                speed of the course had left me feeling burnt out and
                overwhelmed. I needed time to process and absorb the knowledge I
                had gained. Stepping back and taking a breather allowed me to
                reassess my goals and rekindle my passion for coding. It was
                crucial to remind myself that learning is a journey, and it's
                okay to take breaks and tackle challenges at my own pace.
                <br />
                <h4>Embracing Self-Directed Learning</h4>
                With the burnout behind me, I embarked on a period of
                self-directed learning. Armed with a newfound appreciation for a
                balanced learning approach, I dove deeper into the languages
                that interested me the most. By focusing on projects that
                aligned with my passions and career aspirations, I found joy in
                the coding process again. This phase also allowed me to
                reinforce the concepts I had learned during the bootcamp,
                solidifying my understanding.
                <br />
                <h4>The Job Market Realities</h4> As I started contemplating job
                opportunities in the coding world, I couldn't ignore the
                challenges posed by the competitive job market. With many other
                aspiring developers also seeking employment, landing the perfect
                job seems daunting. However, I've come to understand that
                perseverance, a well-crafted portfolio, and a genuine passion
                for coding can set me apart and increase my chances of success.
                <br />
                <h4>Conclusion</h4> My coding journey has been a rollercoaster
                of experiences, from the unstructured beginnings to the intense
                bootcamp and the subsequent burnout. However, each phase taught
                me invaluable lessons about learning, self-care, and the
                importance of staying true to my passions. The job market may
                appear challenging, but I'm determined to showcase my skills
                through meaningful projects and a well-curated portfolio. As I
                move forward, I'm excited to embrace the future and all the
                possibilities that coding has to offer. After all, every
                obstacle I've encountered has only made me a stronger and more
                resilient coder.
              </div>
            )}
          </div>
          <button className="blog__read" onClick={handleToggle1}>
            {showBlog1 ? "Read Less" : "Read More"}
          </button>
        </div>
      </div>
    </div>
  );
}

export default Blog;
