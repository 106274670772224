import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.scss";
import Header from "./components/header/Header";
import Body from "./components/body/Body";
import Footer from "./components/footer/Footer";
import Blog from "./components/blog/Blog";
import Work from "./components/work/Work";
import Photography from "./components/photo/Photography";

function App() {
  return (
    <BrowserRouter>
    <div className="App">
      <Header />
      <Routes>
        <Route path="/" element={<Body/>}/>        
        <Route path="Work" element={<Work/>}/>
        <Route path="Photography" element={<Photography/>}/>
        <Route path="Blog" element={<Blog/>}/>
      </Routes>
      <Footer />
    </div>
    </BrowserRouter>
  );
}

export default App;
