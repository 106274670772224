
import './Body.scss';

function Body() {
    return(
        <section className="body">
            <h2 className="body__heading">Full Stack Web Developer</h2>
            <h3 className="body__p">I love to code cool stuff!</h3>
            <div className="body__image--container">
            <aside className="body__image"></aside>
            </div>
            <aside className="body__about">
                <h4 className="body__name">Hi, I'm Josh. Nice to meet you.</h4>
                <p className="body__about--p">I am a full stack web developer, I create digital products for business and consumer use. </p>
            </aside>
            <div className="body__container">
            <aside className="body__front">
                <div className="body__front--image"></div>
                <h3 className="body__frontend">Front End</h3>
                <p className="body__p">I code things from scratch, and enjoy bringing ideas to life. I love coding in React.js</p>
                <h4 className="body__languages">Languages:</h4>
                <p className="body__languages--p">
                    HTML, CSS, Sass
                </p>
                <h4 className="body__tools">Tools:</h4>
                <p className="body__tools--p">Github, Terminal, VS Code </p>
            </aside>

            <aside className="body__back">
                <div className="body__backend--image"></div>
                <h3 className="body__backend">Back End</h3>
                <p className="body__p">The "Brains" of a website, handling data, and making sure everything runs smoothly.</p>
                <h4 className="body__backend--languages">Languages:</h4>
                <p className="body__backend--p">JavaScript, Node.js, Express.js, MySql</p>
            </aside>
            </div>

            <aside className="body__learning">
                <div className="body__learning--image"></div>

            </aside>
        </section>
    )
}

export default Body;