import { Link } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import "./Header.scss";

function Header() {
  const [isOpen, setIsOpen] = useState(false);
  const [showNav, setShowNav] = useState(true);
  const dropdownRef = useRef();

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      const scrollThreshold = 100;
      setShowNav(scrollY <= scrollThreshold);
      if (isOpen) {
        setIsOpen(false);
      }
    };

    const handleClickOutside = (e) => {
      if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
        setIsOpen(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  return (
    <header className="header">
      <nav
        className="nav"
        style={{ opacity: showNav ? 1 : 0, transition: "opacity 0.5s" }}
      >
          <Link className="link" to="/">
          <h1 className="logo">
            Goost Creative
            </h1>
          </Link>
      

        <div className="hamburger" onClick={handleToggle}>
          <span></span>
          <span></span>
          <span></span>
        </div>

        <ul className={`list ${isOpen ? "open" : ""} `}>
          <li className="list__item">
            <Link className="link" to="Work">
              Work
            </Link>
          </li>
          <li className="list__item">
            <Link className="link" to="Blog">
              Blog
            </Link>
          </li>
          <li className="list__item">
            <Link className="link" to="Photography">
              Photography
            </Link>
          </li>
        </ul>
      </nav>
    </header>
  );
}

export default Header;
