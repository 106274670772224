import "./Photo.scss";


function Photography() {



    return(
        <div className="photo">
        <h1 className="photo__heading">Photography</h1>

        <div className="photo__container">

                <div className="photo__image image1"></div>
                <div className="photo__image image2"></div>
                <div className="photo__image image3"></div>
                <div className="photo__image image4"></div>
                <div className="photo__image image5"></div>
                <div className="photo__image image6"></div>
                <div className="photo__image image7"></div>
                <div className="photo__image image8"></div>
                <div className="photo__image image9"></div>
                <div className="photo__image image10"></div>
                <div className="photo__image image11"></div>
                <div className="photo__image image12"></div>


        </div>
        </div>
    )
}

export default Photography;