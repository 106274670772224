import "./Work.scss";
import site from "../../assets/images/portfolio/site.webp";
import curate from "../../assets/images/portfolio/curate.webP";
import wholesale from "../../assets/images/portfolio/wholesale.webp";

function Work() {
  return (
    <>
      <h1 className="work__heading">Work</h1>

      <div className="work__container">

      <aside className="work__site">
          <a
            className="work__site--heading"
            href={`https://wholesalevendingbc.ca`}
            target="_blank"
            rel="noreferrer"
          >
            {" "}
            <img
              src={wholesale}
              alt="www.wholesalevendingbc.ca Website"
              className="work__image"
            />
          </a>
          <p className="work__blag">
            <h3>Wholesale Vending BC</h3>
            Working with the client to create a site for an up and coming
            business in Vancouver BC. Wholesale Vending BC is a Single Page
            Application built as a sales funnel for customers to view the
            products and services and get in contact with the business owner to
            either buy vending machines or have them installed in their
            workplace for customers and employees. I built Wholesale Vending BC
            in React.js and used{" "}
            <a className="work__blag--link" href="https://emailjs.com" rel="noreferrer" target="_blank">
              EmailJs.com
            </a>{" "}
            to send emails from the contact form to the clients email. The site
            runs seamlessly on all formats and with optimized SEO the site has
            plenty of traffic.
          </p>
        </aside>

        <aside className="work__site">
          <a
            className="work__site--heading"
            href={`https://jpmorganconstruction.com`}
            target="_blank"
            rel="noreferrer"
          >
            {" "}
            <img
              src={site}
              alt="www.jpmorganconstruction.com Website"
              className="work__image"
            />
          </a>
          <p className="work__blag">
            <h3>JP Morgan Construction</h3>I collaborated with a local Vancouver
            construction company to develop their website using ReactJS. The
            focus was on enhancing user experience by showcasing services,
            projects, and contact options. Challenges included optimizing
            performance and ensuring cross-browser compatibility. The outcome is
            a sleek, professional platform that exceeded client expectations,
            empowering the company's online presence and ensuring future
            scalability.
          </p>
        </aside>

        <aside className="work__site">
          <a
            className="work__site--heading"
            href={`https://curateclub.co`}
            target="_blank"
            rel="noreferrer"
          >
            {" "}
            <img
              src={curate}
              alt="www.curateclub.co Website"
              className="work__image"
            />
          </a>
          <p className="work__blag">
            <h3>Curate Club</h3>
            Working with the client to create an Amazon Curated Affiliate
            Marketing site in ReactJS. I successfully crafted a dynamic and
            visually captivating affiliate marketing site using React.js. Tasked
            with curating premium Amazon finds from reputable companies, the
            site seamlessly blends aesthetics with functionality, offering users
            an immersive browsing experience. Client satisfaction soared,
            leading to ongoing development aimed at introducing innovative
            features and further elevating the platform's user engagement.
          </p>
        </aside>

      
      </div>
    </>
  );
}

export default Work;
