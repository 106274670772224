import "./Footer.scss";
import emailjs from "@emailjs/browser";

function Footer() {

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        process.env.REACT_APP_SERVICE_ID,
        process.env.REACT_APP_TEMPLATE_ID,
        e.target,
        process.env.REACT_APP_PUBLIC_KEY
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );

    e.target.reset();
  };

  return (
    <div className="footer">
      <h2 className="footer__header">Lets work together</h2>
      <div className="footer__container">
      
          <form onSubmit={sendEmail} className="form">
            <label htmlFor="to_name" className="form__name">
              <input
                type="text"
                placeholder="Name"
                id="name"
                name="to_name"
                className="form__name--input"
                required
              />
            </label>

            <label htmlFor="emailFrom" className="form__email">
              <input
                type="text"
                placeholder="Email"
                id="emailFrom"
                name="email_from"
                className="form__email--input"
                required
              />
            </label>

            <label htmlFor="message" className="form__message">
              <textarea
                name="message"
                placeholder="What can I help you with?"
                id="message"
                cols="30"
                rows="10"
                className="form__message--input"
                required
              ></textarea>
            </label>
            <button type="submit" variant="contained" className="form__button">
              Send
            </button>
          </form>
    

        <div className="footer__socials">
          <a
            href={`https://github.com/mejoshmann`}
            target="_blank"
            rel="noreferrer"
            className="footer__git"
          >
            {" "}
            <div className="footer__git"></div>
          </a>
          <a
            href={`https://www.instagram.com/wildislandphoto/`}
            target="_blank"
            rel="noreferrer"
            className="footer__insta"
          >
            {" "}
            <div className="footer__insta"></div>
          </a>{" "}
          <p className="footer__copy">&#169; GoostCreative</p>
        </div>
      </div>
    </div>
  );
}

export default Footer;
